exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/Home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-item-1-js": () => import("./../../../src/pages/Item1.js" /* webpackChunkName: "component---src-pages-item-1-js" */),
  "component---src-pages-item-2-js": () => import("./../../../src/pages/Item2.js" /* webpackChunkName: "component---src-pages-item-2-js" */),
  "component---src-pages-item-3-js": () => import("./../../../src/pages/Item3.js" /* webpackChunkName: "component---src-pages-item-3-js" */),
  "component---src-pages-sample-item-2-js": () => import("./../../../src/pages/sample_Item2.js" /* webpackChunkName: "component---src-pages-sample-item-2-js" */),
  "component---src-pages-search-beach-js": () => import("./../../../src/pages/SearchBeach.js" /* webpackChunkName: "component---src-pages-search-beach-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

