import React, { useEffect } from 'react';
import { Drawer, List, ListItem, ListItemText, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const Sidebar = ({ open, onClose, setSidebarWidth }) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const sidebarRef = React.useRef(null);

  useEffect(() => {
    if (typeof window !== 'undefined' && sidebarRef.current) {
    // if (sidebarRef.current) {
      setSidebarWidth(sidebarRef.current.offsetWidth);
    }
  }, [open, setSidebarWidth]);

  return (
    <Drawer
      ref={sidebarRef}
      variant={isMobile ? "temporary" : "persistent"}
      open={open}
      onClose={onClose}
      anchor="left"
      sx={{
        width: open ? sidebarRef.current?.offsetWidth : 0,
        flexShrink: 0,
        opacity: 0.7, // 반투명도
        zIndex: 1200, // z-index 추가
      }}
    >
      <List>
        <ListItem button component={Link} to="/">
          <ListItemText primary="Home"/>
        </ListItem>
        <ListItem button component={Link} to="/">
          <ListItemText primary="Home"/>
        </ListItem>
        {/* <ListItem button component={Link} to="/item1">
          <ListItemText primary="Sea in Your Location"/>
        </ListItem>
        <ListItem button component={Link} to="/item2">
          <ListItemText primary="Item 2" />
        </ListItem>
        <ListItem button component={Link} to="/item3">
          <ListItemText primary="Sea in Your Location" />
        </ListItem> */}
      </List>
    </Drawer>
  );
};

export default Sidebar;