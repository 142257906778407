import React, { useState } from 'react';
import Sidebar from './Sidebar';
import TopMenu from './TopMenu';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';

const Layout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMenuClick = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleContentClick = () => {
    if (sidebarOpen) {
      setSidebarOpen(false);
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <TopMenu onMenuClick={handleMenuClick} />
      <Sidebar open={sidebarOpen} onClose={() => setSidebarOpen(false)} setSidebarWidth={setSidebarWidth} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          marginLeft: isMobile ? 0 : (sidebarOpen ? `${sidebarWidth}px` : '0px'),
          // marginTop: '64px', // Adjust for the height of the AppBar
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: 'calc(100vh - 64px)', // Adjust for the height of the AppBar
          marginTop: '0px',
        }}
        onClick={handleContentClick} // 콘텐츠 클릭 시 사이드바 닫기
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            // maxWidth: '800px', // Adjust the max width as needed
            textAlign: 'center',
            marginLeft: isMobile ? 0 : 'auto',
            marginRight: isMobile ? 0 : 'auto',
            minHeight: '100vh',
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;