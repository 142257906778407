import React from 'react';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Layout from 'components/Layout'; // Layout 컴포넌트 임포트


const theme = createTheme({
  // Customize your theme here
});

const createEmotionCache = () => {
  return createCache({ key: 'css', prepend: true });
};

// wrapRootElement 설정
export const wrapRootElement = ({ element }) => {
  const cache = createEmotionCache();

  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>{element}</ThemeProvider>
    </CacheProvider>
  );
};

// wrapPageElement 추가
export const wrapPageElement = ({ element, props }) => {
  return (
    <Router>
      <Layout {...props}>{element}</Layout>
    </Router>
  );
};